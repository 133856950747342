<template>
  <div class="content">
    <!-- 头部 -->
    <div class="x-hed">
      <van-nav-bar
        class="van-nav-bar"
        :title="planName || ''"
        left-text="返回"
        right-text=""
        color="#FFFFFF"
        left-arrow
        @click-left="onClickLeft"
      />
      <div class="x-title">{{ plantObj.plantName || "" }}安心保障计划</div>
      <div class="x-brief" style="width: 75%;" v-if="plantObj.partner==='anlan'">
        本产品由安诚财产保险股份有限公司承保并负责理赔
      </div>
      <div class="x-brief" v-else>
        本产品由新疆前海联合财产保险股份有限公司承保并负责理赔
      </div>
    </div>
    <!-- table -->
    <div class="x-table">
      <div
        :class="
          'x-table-item ' + (tableIndex == index ? ' x-table-item-on ' : '')
        "
        v-for="(item, index) in dataList"
        :key="index"
        @click="onTableClick(index)"
      >
        <div :class="index != 0 ? 'x-linke' : ''"></div>
        {{ item.typeName }}
      </div>
    </div>
    <!-- list -->
    <div class="x-commodity">
      <!-- 表头 -->
      <van-row class="x-com-th" justify="space-between">
        <van-col span="19">保险责任</van-col>
        <van-col span="5">保险金额</van-col>
      </van-row>
      <!-- 表体 -->
      <van-row
        class="x-com-td"
        v-for="(item, index) in duty"
        :key="index"
        justify="space-between"
      >
        <van-col span="19">{{ item.content }}</van-col>
        <van-col span="5">{{ item.amount }}</van-col>
      </van-row>
      <!-- 表尾 -->
      <div class="x-tail" @click="showExplain" v-if="complication.length>0">《并发症列表说明》</div>
    </div>

    <!-- 新生儿损伤 -->
    <div class="x-commodity" style="padding-top: unset;" v-if="babyDamage.length>0">
      <!-- 表头 -->
      <van-row class="x-com-th" justify="space-between">
        <van-col span="19" style="width: 75%;">新生儿损伤</van-col>
        <van-col span="5" style="width: 25%;">分项保险金额</van-col>
      </van-row>
      <!-- 表体 -->
      <van-row
              class="x-com-td"
              v-for="(item, index) in babyDamage"
              :key="index"
              justify="space-between"
      >
        <van-col span="19">{{ item.content }}</van-col>
        <van-col span="5">{{ item.amount }}</van-col>
      </van-row>
    </div>

    <!-- 标准 -->
    <van-row class="x-standard">
      <van-col span="6" class="x-sta-title" style="margin-top: 8px;">费率标准：</van-col>
      <van-col span="18">
        <div
          :class="
            'x-sta-time ' +
              (standObj.feeId == item.feeId ? ' x-sta-time-on ' : '')
          "
          v-for="(item, index) in feeStandard"
          :key="index"
          @click="onStanClick(item)"
        >
          {{ item.content }}
        </div>
      </van-col>
    </van-row>

    <!-- 支付 -->
    <div class="x-payment">
      <div class="x-row">
        <div class="x-col">
          保费：<span class="x-number">{{ standObj.amount || 0 }}元</span>
        </div>
        <div class="x-col">
          <van-button class="x-submit" @click="onShowNotice"
            >前往投保</van-button
          >
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <van-popup
      v-model="is_notice"
      position="bottom"
      :style="{ height: '45%' }"
      @click-overlay="is_notice = false"
      @click-close-icon="is_notice = false"
      class="x-popup"
    >
      <div class="x-popup-title">温馨提示</div>
      <div class="x-popup-msg">
        你即将进入投保流程：
        请仔细阅读保险条款，投保须知等内容，为保障你的权益，你在销售页面的操作将会被记录并加密储存。
      </div>
      <!-- <div class="x-popup-look">查看《客户告知书》</div> -->
      <div class="x-sub-title">
        <div>
          <van-image v-if="plantObj.partner==='anlan'" width="50px" fit="contain" :src="images.company2" />
          <van-image v-else width="50px" fit="contain" :src="images.company" />
        </div>
        <div class="x-popup-txt" v-if="plantObj.partner==='anlan'">
          本产品由安诚财产保险股份有限公司承保并负责理赔
        </div>
        <div class="x-popup-txt" v-else>
          本产品由新疆前海联合财产保险股份有限公司承保并负责理赔
        </div>
      </div>
      <div class="x-popup-button">
        <van-button type="primary" class="x-button-p" @click="onClike"
          >我知道了</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { mapState } from "vuex";
import { getSession, setSession } from "../util/sessionStorage";
import company from "../assets/company.jpg";
import company2 from "../assets/company2.jpg";

export default {
  components: {},
  computed: {
    ...mapState({
      planName: "planName"
    })
  },
  data() {
    return {
      images: {
        company,company2
      },
      is_notice: false,
      tableIndex: 2, //标签
      standObj: {}, //标准

      dataList: [],
      plantObj: {},
      duty: [], //保险列表
      feeStandard: [], //标准

      //Jervis add by
      complication: [], //并发症 Jervis add by
      babyDamage: [] //新手儿损伤
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //   获取详情
    getInsuranceDetails() {
      this.$api
        .getInsuranceDetails({ id: this.$route.params.id })
        .then((res) => {
          console.log("获取列表==>>", res);
          if (res.code != 1000) {
            this.dataObj = {};
            return;
          }
          let title = (res.data.plant.plantName || "") + "安心保障计划";
          document.title = title;
          this.$store.commit("setPlanName", title);

          this.plantObj = res.data.plant;
          this.dataList = res.data.items;

          //Jervis add by
          this.tableIndex = this.dataList.length - 1;

          if (this.dataList.length > 0) {
            this.infoTable();
          }
        });
    },

    // tabinit
    infoTable() {
      this.duty = this.dataList[this.tableIndex].duty;
      this.feeStandard = this.dataList[this.tableIndex].feeStandard;
      let complication = this.dataList[this.tableIndex].complication;

      //Jervis add by
      this.babyDamage = this.dataList[this.tableIndex].babyDamage;
      this.complication = complication;

      this.standObj = {};
      this.$store.commit("setExplain", complication);
    },

    // table点击
    onTableClick(index) {
      this.tableIndex = index;
      this.infoTable();
    },
    // 查看说明
    showExplain() {
      this.$router.push({ path: "/showExplain/" });
    },
    // 选择标准
    onStanClick(item) {
      this.standObj = item;
    },
    // 显示须知
    onShowNotice() {
      console.log("投保==>>", this.standObj);
      if (!this.standObj.feeId) {
        Dialog.alert({ message: "请选择费率标准！" });
        return;
      }
      this.is_notice = true;
    },
    //投保
    onClike() {
      console.log("投保==>>", this.standObj);
      if (!this.standObj.feeId) {
        Dialog.alert({ message: "请选择费率标准！" });
        return;
      }
      let typeId = this.dataList[this.tableIndex].typeId;
      setSession("typeId", typeId);
      setSession("feeId", this.standObj.feeId);
      setSession("partner", this.plantObj.partner);
      this.$router.push({ path: "/Important/" + this.standObj.feeId });
    }
  },
  mounted() {
    this.getInsuranceDetails();
  }
};
</script>
<style lang="less" scoped>
.x-popup {
  padding: 16px;
  box-sizing: border-box;

  .x-popup-title {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #1a1a1a;
    margin-top: 5px;
  }

  .x-popup-msg {
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
    margin-top: 12px;
  }

  .x-popup-look {
    font-size: 14px;
    font-weight: 400;
    color: #0457ff;
    margin-top: 5px;
  }

  .x-sub-title {
    display: flex;
    flex-direction: row;
    padding-top: 12px;
    box-sizing: border-box;
    border-top: 1px solid #e9e9e9;
    margin-top: 12px;

    .x-popup-txt {
      font-size: 14px;
      font-weight: 400;
      color: #035e93;
      margin-left: 8px;
    }
  }

  .x-popup-button {
    margin-top: 20px;
    text-align: center;

    .x-button-p {
      width: 252px;
      height: 44px;
      background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      margin: 0px auto;
      border: none;
    }
  }
}

.van-nav-bar {
  background: none;
  border: none;
  color: white;

  ::v-deep .van-icon {
    color: #fff;
  }
  ::v-deep .van-nav-bar__text {
    color: #fff;
  }
  ::v-deep .van-ellipsis {
    color: #fff;
  }
}
.van-nav-bar::after {
  background: none;
  border: none;
  color: white;
}

.x-hed {
  width: 100%;
  background: linear-gradient(90deg, #fc7c44 0%, #ee4a3a 100%);
  box-sizing: border-box;
  padding-bottom: 16px;

  .x-title {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    margin: 0px 16px;
    margin-top: 20px;
  }

  .x-brief {
    background: rgba(255, 223, 206, 0.5);
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #f94233;
    margin: 0px 16px;
    margin-top: 6px;
    padding: 4px;
  }
}

.x-table {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  .x-table-item {
    flex: 1;
    text-align: center;
    padding: 12px 0px;
    background: #d04a2c;
    font-size: 14px;
    font-weight: 400;
    color: #e6e6e6;
    position: relative;

    .x-linke {
      position: absolute;
      top: 30%;
      left: -0.5px;
      width: 1px;
      height: 50%;
      background-color: #f09d81;
    }
  }

  .x-table-item-on {
    background: linear-gradient(90deg, #ff4b2b 0%, #f55923 100%);
  }
}

.x-commodity {
  padding: 16px;
  box-sizing: border-box;
  background-color: white;

  .x-com-th {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #1a1a1a;
  }
  .x-com-td {
    margin: 8px 0px;
  }
  .x-com-td .van-col:nth-child(1) {
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
  }

  .x-com-td .van-col:nth-child(2) {
    font-size: 18px;
    font-weight: 400;
    color: #f55923;
  }

  .van-col:nth-child(1) {
    text-align: left;
  }

  .van-col:nth-child(2) {
    text-align: right;
  }
}

.x-tail {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2c70f5;
  border-top: 1px solid #e9e9e9;
  padding: 11px 0px;
  /*padding-bottom: 0px;*/
  margin-top: 10px;
  border-bottom: 1px solid #e9e9e9;
}

.x-standard {
  margin-top: 8px;
  padding: 12px 16px;
  box-sizing: border-box;
  background-color: white;

  .x-sta-title {
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;
  }

  .x-sta-time {
    margin-bottom: 8px;
    padding: 9px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
  }

  .x-sta-time-on {
    background: #ddecff;
    border: 1px solid#ddecff;
    color: #2c70f5;
  }
}

.x-payment {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;

  .x-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    .x-col {
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
      display: flex;
      flex-direction: row;
      align-items: center;

      .x-number {
        font-size: 18px;
        font-weight: 600;
        color: #f55923;
      }
    }
  }

  .x-submit {
    background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    padding: 6px 12px;
    height: 32px;
    border: none;
  }
}
</style>
